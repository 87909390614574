import logo from './brick.png';
import './App.css';

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <h1>Estamos en construcción</h1>
        <h2>Vuelve pronto</h2>
        <img src={logo} className='App-logo' alt='logo' />
      </header>
    </div>
  );
}

export default App;
